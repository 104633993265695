export const columnsDefault = [
  {
    Header: "ID",
    accessor: "id",
    maxWidth: 32,
    width: 32,
  },
  {
    Header: "Nome da Carga",
    accessor: "nome",
  },
  {
    Header: "Data / Hora",
    accessor: "dataHora",
  },
  {
    Header: "Responsável",
    accessor: "responsavel",
  },
  {
    Header: "Status",
    accessor: "status",
    width: 200,
  },
  {
    Header: "Visualizar",
    accessor: "visualizar",
    maxWidth: 52,
    width: 52,
  },
  {
    Header: "Excluir",
    accessor: "excluir",
    maxWidth: 62,
    width: 62,
  },
];

export const contractTabColumns = [
  {
    Header: "ID",
    accessor: "id",
    maxWidth: 32,
    width: 32,
  },
  {
    Header: "Nome da Carga",
    accessor: "nome",
  },
  {
    Header: "Data / Hora",
    accessor: "dataHora",
  },
  {
    Header: "Responsável",
    accessor: "responsavel",
  },
  {
    Header: "Status",
    accessor: "status",
    width: 200,
  },
  {
    Header: "Nº Contratos",
    accessor: "totalContratos",
    width: 200,
  },
  {
    Header: "Visualizar",
    accessor: "visualizar",
    maxWidth: 52,
    width: 52,
  },
  {
    Header: "Excluir",
    accessor: "excluir",
    maxWidth: 62,
    width: 62,
  },
];

export const userTabColumns = [
  {
    Header: "ID",
    accessor: "id",
    maxWidth: 32,
    width: 32,
  },
  {
    Header: "Nome da Carga",
    accessor: "nome",
  },
  {
    Header: "Data / Hora",
    accessor: "dataHora",
  },
  {
    Header: "Responsável",
    accessor: "responsavel",
  },
  {
    Header: "Status",
    accessor: "status",
    width: 200,
  },
  {
    Header: "Nº Usuários",
    accessor: "totalCargaUsers",
    width: 200,
  },
  {
    Header: "Visualizar",
    accessor: "visualizar",
    maxWidth: 52,
    width: 52,
  },
  {
    Header: "Excluir",
    accessor: "excluir",
    maxWidth: 62,
    width: 62,
  },
];

export const employeeTabColumns = [
  {
    Header: "ID",
    accessor: "id",
    maxWidth: 32,
    width: 32,
  },
  {
    Header: "Nome da Carga",
    accessor: "nome",
  },
  {
    Header: "Data / Hora",
    accessor: "dataHora",
  },
  {
    Header: "Responsável",
    accessor: "responsavel",
  },
  {
    Header: "Status",
    accessor: "status",
    width: 200,
  },
  {
    Header: "Nº funcionários",
    accessor: "totalFuncionarios",
    width: 200,
  },
  {
    Header: "Visualizar",
    accessor: "visualizar",
    maxWidth: 52,
    width: 52,
  },
  {
    Header: "Excluir",
    accessor: "excluir",
    maxWidth: 62,
    width: 62,
  },
];

export const columnsMit = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Nome da Carga",
    accessor: "nome",
  },
  {
    Header: "Data / Hora",
    accessor: "dataHora",
  },
  {
    Header: "Responsável",
    accessor: "responsavel",
  },
  {
    Header: "Visualizar",
    accessor: "visualizar",
    maxWidth: 32,
    width: 32,
  },
  {
    Header: "Excluir",
    accessor: "excluir",
    maxWidth: 42,
    width: 42,
  },
];

export const columnsEditarContratos = [
  {
    Header: "Código",
    accessor: "id",
  },
  {
    Header: "Nome",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
];
