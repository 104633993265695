import React from "react";

// Redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

// Router
import { BrowserRouter } from "react-router-dom";
import Interceptor from "./services/Interceptor";
import SwitchRoutes from "./components/switchRoutes";
import routes from "./routes";

// Styled
import { ThemeProvider } from "styled-components";
import GlobalStyled from "./assets/styles/global";
import Themes from "./assets/styles/themes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import TitleManager from "./components/titleManager";

const App = () => (
  <Provider store={store}>
    <Interceptor />
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={Themes.default}>
        <GlobalStyled />
        <BrowserRouter>
          <TitleManager />
          <SwitchRoutes routes={routes} />
          <ToastContainer />
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export default App;
