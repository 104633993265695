import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router";

import { ObjIsEmpty, dowloadFile } from "../../utils/helper";

import Topo from "../topo";
import Button from "../../components/button";
import FiltroContrato from "../filtroGerenciaAdm";
import Tabela from "../tabelas/admin";
import Modal from "../modal";
import NovoModal from "../NovoModal";
import ModalAdm from "../modalAdm";
import ModalAction from "../ModalActions/contrato";
import ModalVendedores from "../modalAdm/vendedores";
import ModalContratos from "../modalAdm/contratos";
import ModalCarga from "../modalAdm/cargaAdministradores";
import ModalConfirmaCarga from "../modalAdm/confirmaCarga";
import ModalConfirmaExclusaoContrato from "./Tablecontratos/modalConfirmaExclusao";
import ModalFinalizaCarga from "../modalAdm/finalizaCarga";
import ModalErroCarga from "../modalAdm/erroCarga";
import ModalVisualiza from "../modalVinculoVisualizar";
import ModalEditarContratos from "./modalEditarContrato";
import {
  userTabColumns,
  employeeTabColumns,
  contractTabColumns,
} from "./columns";

import FinanciamentoContrato from "./Tablecontratos/financiamento";
import SeguroContrato from "./Tablecontratos/seguro";
import CartaoMitContrato from "./Tablecontratos/cartaoMit";

import { Creators as ModalCreators } from "../../store/ducks/modalAdm";
import { Creators as AcaoAdmCreators } from "../../store/ducks/acaoAdm";
import { Creators as AprovarContratoCreators } from "../../store/ducks/aprovarContratos";
import { Creators as CargaAcaoAdmCreators } from "../../store/ducks/cargaAcaoAdm";
import { Creators as ModalActionCreators } from "../../store/ducks/modalActionContrato";

import contrato from "../../assets/img/sistema/contrato.svg";
import adm from "../../assets/img/sistema/adm.svg";
import vendedores from "../../assets/img/sistema/vendedores.svg";
import reload from "../../assets/img/sistema/reload.svg";
import { Container, Conteudo, LoadAdmActionButton } from "./styles";
import { DebounceInput } from "react-debounce-input";

import { api } from "../../services/api";
import { ModalRemoverVinculo } from "./ModalRemoverVinculo";
import { toast } from "react-toastify";

function GerenciaAdm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [itemSelect, setItemSelect] = useState({});
  const [filtroContrato, setFiltroContrato] = useState("");
  const [loadingContratosFuncionario, setLoadingContratosFuncionario] =
    useState(false);
  const [aba, setAba] = useState("adm");
  const [searchInput, setSearchInput] = useState("");

  const [sellerManagers, setSellerManagers] = useState([]);
  const [modalExcluirContrato, setModalExcluirContrato] = useState(false);
  const [modalremoverVinculos, setModalRemoverVinculos] = useState(false);
  const [modalRemoverVinculosDocumento, setModalRemoverVinculosDocumento] = useState(null);
  const [modalExcluirFuncionario, setModalExcluirFuncionario] = useState(false);
  const [documentoExcluir, setDocumentoExcluir] = useState(null);
  const [dadosExcluirContrato, setDadosExcluirContrato] = useState({});
  const arrFinanciamento = [
    "financiamento",
    "financiamento_valor",
    "financiamento_valor_fixo",
  ];

  const {
    modal_adm,
    modal_vendedores,
    modal_contratos,
    modal_carga,
    modal_confirma_carga,
    modal_finaliza_carga,
  } = useSelector((state) => state.modalAdm);

  const { modal_editar_contrato, dados: dadosContrato } = useSelector(
    (state) => state.modalEditarContrato
  );

  const { modal_action, dados } = useSelector(
    (state) => state.modalActionContrato
  );

  const { modal_erro_carga, menssagem_de_erro, data, erroData, loading } =
    useSelector((state) => {
      return state.cargaAcaoAdm;
    });

  const { modal_vinculo_visualizar } = useSelector(
    (state) => state.modalVinculo
  );

  const config = {
    title: menssagem_de_erro?.titulo ? menssagem_de_erro?.titulo : "Contratos",
    description: menssagem_de_erro?.descricao
      ? menssagem_de_erro?.descricao
      : "Algo de errado aconteceu tente novamente",
    closeModal: closeModal,
    list: erroData,
  };

  const configEditar = {
    title: "Editar Contrato",
    description: "Confirma a alteração",
    closeModal: closeModalConfirmaContratos,
    onClick: editarContrato,
  };

  const {
    location: { itemselect: url, select },
  } = history;

  if (!url) {
    history.push("/sistema");
  }

  const id = !ObjIsEmpty(itemSelect) ? itemSelect?.value : url?.value;

  const userColumns = React.useMemo(() => userTabColumns, []);
  const employeeColumns = React.useMemo(() => employeeTabColumns, []);
  const contractColumns = React.useMemo(() => contractTabColumns, []);

  const confirExclusaoContrato = {
    dados: dadosExcluirContrato,
    onClose: () => setModalExcluirContrato(false),
    type: data.acao_type,
    contrato_id: id,
  };

  function closeModal() {
    dispatch(CargaAcaoAdmCreators.modal_erro_carga());
  }

  function editarContrato() {
    dispatch(
      AprovarContratoCreators.request_contrato_adm({
        acao_type: data.acao_type,
        user: dados,
        contrato: dadosContrato,
        id,
      })
    );
  }

  function closeModalConfirmaContratos() {
    dispatch(ModalActionCreators.close());
  }

  async function exportarPontos(acao_id) {
    setLoadingContratosFuncionario(true);

    await dowloadFile(`acao/${acao_id}/export`);

    setLoadingContratosFuncionario(false);
  }

  useEffect(() => {
    if (url) {
      dispatch(CargaAcaoAdmCreators.request(id, aba));
    }
  }, [url, dispatch, id, aba]);

  useEffect(() => {
    setFiltroContrato("");
  }, [data]);

  const searchTimeoutRef = useRef(null);

  const handleSearchSellerManager = (e) => {
    const value = e.target?.value ?? e;
    setSearchInput(value);

    const cleanedValue = value.replace(/[.-]/g, "").trim();

    clearTimeout(searchTimeoutRef.current);

    if (cleanedValue.length >= 3) {
      searchTimeoutRef.current = setTimeout(() => {
        api
          .get(`funcionario/search-by-name-cpf/${id}/${cleanedValue}`)
          .then((response) => {
            setSellerManagers(response.data.resource);
          });
      }, 800);
    } else {
      setSellerManagers([]);
    }
  };

  const handleRemoveBind = async (documento) => {
    await api
      .post(`funcionario/unlock-economic-group/${id}/${documento}`)
      .then((response) => {
        if (response.data.resource) {
          handleSearchSellerManager(searchInput);
        }
      })
      .catch((error) => {
        console.error("Erro ao desbloquear funcionário:", error);
      });
  };

  const handleExcluirFuncionario = async (documento) => {
    try {
      const response = await api.delete(`funcionario/remove-from-action/${id}/${documento}`);
      if (response.status === 204) {
        setSellerManagers((prevManagers) =>
          prevManagers.filter((manager) => manager.documento !== documento)
        );
        dispatch(CargaAcaoAdmCreators.request(id, aba));
        toast.success("Funcionário excluído da ação com sucesso!");
      } else if (response.data.resource) {
        handleSearchSellerManager(searchInput);
      }
    } catch (error) {
      console.error("Erro ao excluir funcionário:", error);
    }
  };

  return (
    <>
      <Container>
        <Conteudo>
          <Topo />
          <FiltroContrato
            select={select}
            setItemSelect={setItemSelect}
            placeholder={url?.label}
            tipo={data.acao_type}
          />
          <div className="conteudo">
            <div className="abas">
              <span
                className={`${aba === "adm" ? "item active" : "item"}`}
                onClick={() => {
                  dispatch(
                    AcaoAdmCreators.acao_type({
                      type: "user",
                      acao_id: id,
                    })
                  );
                  setAba("adm");
                }}
              >
                <img src={adm} alt="" /> Administradores
              </span>
              <span
                className={`${aba === "vendedores" ? "item active" : "item"}`}
                onClick={() => {
                  dispatch(
                    AcaoAdmCreators.acao_type({
                      type: "funcionario",
                      acao_id: id,
                    })
                  );
                  setAba("vendedores");
                }}
              >
                <img src={vendedores} alt="" /> Vendedores e Gerentes
              </span>
              <span
                className={`${aba === "contratos" ? "item active" : "item"}`}
                onClick={() => {
                  dispatch(
                    AcaoAdmCreators.acao_type({
                      type: "contrato",
                      acao_id: id,
                    })
                  );
                  setAba("contratos");
                }}
              >
                <img src={contrato} alt="" /> Contratos
              </span>
            </div>

            {aba === "adm" && (
              <>
                <div className="topo">
                  <span>Total de ADM - {data.admList.length}</span>
                  <div className="funcionario">
                    <LoadAdmActionButton
                      type="button"
                      isLoading={loading}
                      onClick={() =>
                        dispatch(CargaAcaoAdmCreators.request(id, aba))
                      }
                    >
                      <img src={reload} alt="" />
                    </LoadAdmActionButton>

                    <Button
                      active={true}
                      title="Cadastrar ADM"
                      onClick={() =>
                        dispatch(ModalCreators.modal_carga("user"))
                      }
                    />
                  </div>
                </div>

                <Tabela
                  columns={userColumns}
                  data={data.admList ? data.admList : []}
                  tipo="adm"
                />
              </>
            )}

            {aba === "vendedores" && (
              <>
                <div className="topo">
                  <span>
                    Total de Vendedores e Gerentes - {data.vendedorList.length}
                  </span>

                  <div className="funcionario">
                    <LoadAdmActionButton
                      type="button"
                      isLoading={loading}
                      onClick={() =>
                        dispatch(CargaAcaoAdmCreators.request(id, aba))
                      }
                    >
                      <img src={reload} alt="" />
                    </LoadAdmActionButton>
                    {/* <Button
                      active={true}
                      title="Funcionarios"
                      onClick={() => {
                        exportarPontos(data.vendedorList[0].acao_id);
                      }}
                      loading={loadingContratosFuncionario}
                    /> */}

                    <Button
                      active={true}
                      title="Cadastrar Vendedores e Gerentes"
                      onClick={() =>
                        dispatch(ModalCreators.modal_carga("funcionario"))
                      }
                    />
                  </div>
                </div>

                <Tabela
                  columns={employeeColumns}
                  data={data.vendedorList ? data.vendedorList : []}
                  tipo="vendedores"
                />

                <div className="container-editar-contrato">
                  <div className="title" style={{ marginBottom: "10px" }}>
                    <h3>
                      Preencha o campo abaixo para localizar um vendedor ou
                      gerente
                    </h3>
                  </div>

                  <div
                    className="container-busca"
                    style={{ marginBottom: "20px" }}
                  >
                    <DebounceInput
                      placeholder="Nome ou CPF"
                      onChange={(e) => handleSearchSellerManager(e)}
                      style={{ width: "550px", maxWidth: "100%" }}
                      value={searchInput}
                    />
                  </div>

                  {sellerManagers.length > 0 && (
                    <div style={{ overflowX: "auto" }}>
                      <table
                        style={{
                          width: "100%",
                          minWidth: "850px", // Mantém o layout adequado para colunas
                          borderCollapse: "collapse",
                        }}
                      >
                        <thead>
                          <tr>
                            {["Nome", "Documento", "Código Revenda", "Grupo Econômico", "Cargo", "Ação", "Excluir"].map((header, idx) => (
                              <th
                                key={idx}
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  textAlign: "left",
                                  padding: "12px 15px",
                                  whiteSpace: "nowrap", // Evita quebra indesejada
                                  borderBottom: "2px solid rgba(0,0,0,0.1)",
                                }}
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {sellerManagers.map((sellerManager, index) => (
                            <tr
                              key={index}
                              style={{
                                background: index % 2 === 0 ? "rgba(0,0,0,.04)" : "rgba(0,0,0,.01)",
                              }}
                            >
                              {[
                                sellerManager.nome,
                                sellerManager.documento,
                                sellerManager.economic_group_locked ? (sellerManager.codRevend || "-") : "-",
                                sellerManager.economic_group_locked ? (sellerManager.grupo_economico || "-") : "-",
                                sellerManager.economic_group_locked ? sellerManager.tipo : "-",
                                sellerManager.economic_group_locked ? (
                                  <div
                                    style={{
                                      color: "#930035",
                                      padding: "7px 0",
                                      cursor: "pointer",
                                      fontSize: "15px",
                                    }}
                                    onClick={() => {
                                      setModalRemoverVinculos(!modalremoverVinculos);
                                      setModalRemoverVinculosDocumento(sellerManager.documento);
                                    }}
                                  >
                                    <span>Remover vínculos</span>
                                  </div>
                                ) : (
                                  "-"
                                ),
                                <div
                                  style={{
                                    color: "#930035",
                                    padding: "7px 0",
                                    cursor: "pointer",
                                    fontSize: "15px",
                                  }}
                                  onClick={() => {
                                    setModalExcluirFuncionario(true);
                                    setDocumentoExcluir(sellerManager.documento);
                                  }}
                                >
                                  <span>Excluir</span>
                                </div>
                              ].map((value, idx) => (
                                <td
                                  key={idx}
                                  style={{
                                    padding: "12px 15px",
                                    fontSize: "14px",
                                    color: "rgba(0,0,0,.7)",
                                    textAlign: "left",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {value}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                </div>
              </>
            )}

            {aba === "contratos" && (
              <>
                <div className="topo">
                  <span>Total de Contratos - {data.contratosList.length}</span>
                  <div className="funcionario">
                    <LoadAdmActionButton
                      type="button"
                      isLoading={loading}
                      onClick={() =>
                        dispatch(CargaAcaoAdmCreators.request(id, aba))
                      }
                    >
                      <img src={reload} alt="" />
                    </LoadAdmActionButton>
                    <Button
                      active={true}
                      title="Cadastrar Contrato"
                      onClick={() => dispatch(ModalCreators.modal_carga())}
                    />
                  </div>
                </div>

                <Tabela
                  columns={contractColumns}
                  data={data.contratosList ? data.contratosList : []}
                  tipo="contratos"
                />

                <div className="container-editar-contrato">
                  <div className="title">
                    <h3>
                      Preencha os campos abaixo para localizar um contrato
                    </h3>
                  </div>

                  <div className="container-busca">
                    <DebounceInput
                      placeholder="Nª do contrato"
                      type="number"
                      debounceTimeout={1000}
                      value={filtroContrato}
                      onChange={(e) => {
                        setFiltroContrato(e.target.value);
                      }}
                    />
                  </div>

                  {filtroContrato !== "" &&
                  arrFinanciamento.includes(data.acao_type) ? (
                    <FinanciamentoContrato
                      filtro={filtroContrato}
                      setModalExcluirContrato={setModalExcluirContrato}
                      setDadosExcluirContrato={setDadosExcluirContrato}
                    />
                  ) : null}

                  {filtroContrato !== "" && data.acao_type === "seguro" ? (
                    <SeguroContrato
                      filtro={filtroContrato}
                      setModalExcluirContrato={setModalExcluirContrato}
                      setDadosExcluirContrato={setDadosExcluirContrato}
                    />
                  ) : null}

                  {filtroContrato !== "" && data.acao_type === "cartao_mit" ? (
                    <CartaoMitContrato
                      filtro={filtroContrato}
                      setModalExcluirContrato={setModalExcluirContrato}
                      setDadosExcluirContrato={setDadosExcluirContrato}
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
        </Conteudo>

        <Modal component={ModalAdm} active={modal_adm} />
        <Modal component={ModalVendedores} active={modal_vendedores} />
        <Modal component={ModalContratos} active={modal_contratos} />

        <Modal component={ModalCarga} active={modal_carga} />

        <Modal component={ModalConfirmaCarga} active={modal_confirma_carga} />

        <Modal
          component={ModalConfirmaExclusaoContrato}
          active={modalExcluirContrato}
          rest={confirExclusaoContrato}
        />

        <Modal component={ModalFinalizaCarga} active={modal_finaliza_carga} />
        <Modal
          component={ModalErroCarga}
          active={modal_erro_carga}
          rest={config}
        />

        <Modal
          component={ModalEditarContratos}
          active={modal_editar_contrato}
        />

        <Modal
          component={ModalAction}
          active={modal_action}
          rest={configEditar}
        />

        <Modal component={ModalVisualiza} active={modal_vinculo_visualizar} />
        <NovoModal
          open={modalremoverVinculos}
          handleClose={() => setModalRemoverVinculos(!modalremoverVinculos)}
        >
          <ModalRemoverVinculo
            id={id}
            closeModal={() => setModalRemoverVinculos(!modalremoverVinculos)}
            accept={async () => {
              if (modalRemoverVinculosDocumento)
                await handleRemoveBind(modalRemoverVinculosDocumento);
            }}
          />
        </NovoModal>
        <NovoModal
          open={modalExcluirFuncionario}
          handleClose={() => setModalExcluirFuncionario(false)}
        >
          <ModalRemoverVinculo
            id={id}
            closeModal={() => setModalExcluirFuncionario(false)}
            accept={async () => {
              if (documentoExcluir) {
                await handleExcluirFuncionario(documentoExcluir);
              }
            }}
          />
        </NovoModal>
      </Container>
    </>
  );
}

export default GerenciaAdm;
