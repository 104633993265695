import React, { useEffect, useState } from "react";
import { Creators as ModalCreators } from "../../store/ducks/modalAdm";
import { useDispatch, useSelector } from "react-redux";

import Tabela from "../tabelas/modalTableAdmin";
import TabelaSeguro from "../tabelas/modalTableAdminSeguro";

import { Adm, Topo } from "./styles";
import { columnsDefault, seguro, mit, speed } from "./columns";

import fechar from "../../assets/img/sistema/close.svg";
import icone from "../../assets/img/sistema/contrato-laranja.svg";
import { tipoFinanciamento } from "../../utils/helper";

function ModalContratos() {
  const dispatch = useDispatch();
  const [dadosFormat, setDadosFormat] = useState([]);
  const { contratos } = useSelector((state) => state.acaoAdm);
  const {
    data: { acao_type },
  } = useSelector((state) => state.cargaAcaoAdm);

  function parseAdmData(data) {
    const parsedData = data.map(({ content, regra }) => {
      const parsedContent = JSON.parse(content);

      return {
        ...parsedContent,
        ruleName: regra.name,
        ruleType: regra.type,
      };
    });

    setDadosFormat(parsedData);
  }

  const formatDateField = (data, campo) => {
    if (data[campo]) {
      const match = data[campo].match(/^(\d{2})\/(\d{2})\/(\d{4}),/);
      if (match) {
        return `${match[1]}/${match[2]}/${match[3]}`;
      }
    }
    return data[campo];
  };

  function close() {
    dispatch(ModalCreators.modal_contratos());
  }

  const columns = React.useMemo(() => columnsDefault, []);

  const columnsSeguro = React.useMemo(() => seguro, []);

  const columnsMit = React.useMemo(() => mit, []);

  const columnsSpeed = React.useMemo(() => speed, []);

  useEffect(() => {
    if (contratos?.length) {
      if (contratos[0].regra) {
        parseAdmData(contratos);
      } else {
        setDadosFormat(contratos);
      }
    }
  }, [contratos]);

  return (
    <Adm>
      <Topo>
        <h3>
          <img src={icone} alt="" />
          Contratos
        </h3>

        <img
          className="close-modal"
          src={fechar}
          alt=""
          onClick={() => close()}
        />
      </Topo>

      {tipoFinanciamento.includes(acao_type) && (
        <Tabela
          columns={columns}
          data={dadosFormat.map((data) => ({
            ...data,
            datCntl: formatDateField(data, "datCntl"),
            datcntrt: formatDateField(data, "datcntrt"),
          }))}
          pageSizes={10}
          ver={false}
          modal={true}
        />
      )}

      {acao_type === "financiamento" && (
        <Tabela
          columns={columns}
          data={dadosFormat.map((data) => ({
            ...data,
            datCntl: formatDateField(data, "datCntl"),
            datcntrt: formatDateField(data, "datcntrt"),
          }))}
          ver={false}
          modal={true}
        />
      )}

      {acao_type === "seguro" && (
        <TabelaSeguro
          columns={columnsSeguro}
          data={dadosFormat}
          ver={false}
          modal={true}
        />
      )}

      {acao_type === "cartao_mit" && (
        <Tabela
          columns={columnsMit}
          data={dadosFormat.map((data) => ({
            ...data,
            datCntl: formatDateField(data, "datCntl"),
            datcntrt: formatDateField(data, "datcntrt"),
          }))}
          ver={false}
          modal={true}
        />
      )}

      {acao_type === "speed" && (
        <Tabela
          columns={columnsSpeed}
          data={dadosFormat}
          ver={false}
          modal={true}
        />
      )}
    </Adm>
  );
}

export default ModalContratos;
