/**
 * Types
 */
export const Types = {
    STORE_CARGA_ACAO_ADM: "STORE_CARGA_ACAO_ADM",
    UPDATE_CARGA_ACAO_ADM: "UPDATE_CARGA_ACAO_ADM",

    REQUETS_CARGA_ACAO_ADM: "REQUETS_CARGA_ACAO_ADM",
    REQUEST_STATUS_ACAO_ADM: "REQUEST_STATUS_ACAO_ADM",
    REQUEST_VINCULAR_CONTRATOS: "REQUEST_VINCULAR_CONTRATOS",
    REQUEST_APROVAR_CONTRATOS: "REQUEST_APROVAR_CONTRATOS",

    SET_CARGA_ACAO_ADM: "SET_CARGA_ACAO_ADM",

    SUCCESS_CARGA_ACAO_ADM: "SUCCESS_CARGA_ACAO_ADM",
    FAILUIRE_CARGA_ACAO_ADM: "FAILUIRE_CARGA_ACAO_ADM",
    MODAL_ERRO_CARGA_ADM: "MODAL_ERRO_CARGA_ADM",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    loading: false,
    loading_status_acao: false,
    error: false,
    modal_erro_carga: false,
    menssagem_de_erro: {
        titulo: "",
        descricao: "",
    },

    erroData: [],

    data: {
        admList: [],
        vendedorList: [],
        contratosList: [],
        approve_enable: null,
        bind_enable: null,
        status: null,
        acao_type: "",
    },
};

export default function CargaAcaoAdm(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.STORE_CARGA_ACAO_ADM:
            return { ...state, loading: true, error: false };
            
        case Types.UPDATE_CARGA_ACAO_ADM:
            return { ...state, loading: true, error: false };

        case Types.REQUETS_CARGA_ACAO_ADM:
            return { ...state, loading: true, error: false };

        case Types.SET_CARGA_ACAO_ADM:
            return {
                ...state,
                loading: false,
                data: {
                    admList: action.payload.admList,
                    vendedorList: action.payload.vendedorList,
                    contratosList: action.payload.contratosList,
                    approve_enable: action.payload.approve_enable,
                    bind_enable: action.payload.bind_enable,
                    status: action.payload.status,
                    acao_type: action.payload.acao_type,
                },
            };

        case Types.SUCCESS_CARGA_ACAO_ADM:
            return {
                ...state,
                loading: false,
                loading_status_acao: false,
                error: false,
            };

        case Types.FAILUIRE_CARGA_ACAO_ADM:
            return { ...state, loading: false, error: true };

        case Types.MODAL_ERRO_CARGA_ADM:
            return {
                ...state,
                loading: false,
                modal_erro_carga: !state.modal_erro_carga,
                menssagem_de_erro: action.payload,
                erroData: action.payload?.error ? action.payload.error : [],
            };

        case Types.REQUEST_STATUS_ACAO_ADM:
            return { ...state, loading_status_acao: true, error: false };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    request: (id, aba) => {
        aba = aba === undefined ? "contratos" : aba;
        
        return {
            type: Types.REQUETS_CARGA_ACAO_ADM,
            payload: id,
            aba: aba,
        };
    },

    store: (data) => {
        return {
            type: Types.STORE_CARGA_ACAO_ADM,
            payload: { data },
        };
    },

    update: (data) => {
        return {
            type: Types.UPDATE_CARGA_ACAO_ADM,
            payload: { data },
        };
    },

    modal_erro_carga: () => {
        return {
            type: Types.MODAL_ERRO_CARGA_ADM,
        };
    },

    change_status_acao: () => {
        return {
            type: Types.REQUEST_STATUS_ACAO_ADM,
        };
    },

    change_status_vinculo: () => {
        return {
            type: Types.REQUEST_VINCULAR_CONTRATOS,
        };
    },

    change_status_aprovar_vinculo: () => {
        return {
            type: Types.REQUEST_APROVAR_CONTRATOS,
        };
    },
};
