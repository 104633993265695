import warning from "../../../assets/img/sistema/warning.svg";
import closeImg from "../../../assets/img/sistema/close.svg";
import { Container, Header } from "./styles";
import Button from "../../button";
import { useState } from "react";
export function ModalRemoverVinculo(props) {
  const { accept } = props;
  const [loading, setLoading] = useState(false);

  async function handleAccept() {
    try {
      setLoading(true);
      await accept();
      props.closeModal();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container className="vinculo">
      <Header className="vinculo">
        <div className="container-img-text">
          <img src={warning} alt="" />
          <h3>Atenção</h3>
        </div>

        <div
          className="container-img"
          onClick={() => {
            if (props.closeModal) {
              props.closeModal();
            }
          }}
        >
          <img src={closeImg} alt="" />
        </div>
      </Header>

      <div className="content">
        <p>
          Todos os contratos associados a este funcionário voltarão ao estado de
          'Aguardando Vínculo'. Esta ação é irreversível. <br/>Deseja continuar?
        </p>

        <div className="container-btns">
          <Button
            active={true}
            title="Não"
            onClick={() => {
              if (props.closeModal) {
                props.closeModal();
              }
            }}
          />
          <Button title="Sim" loading={loading} onClick={handleAccept} />
        </div>
      </div>
    </Container>
  );
}
