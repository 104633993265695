import { useEffect } from "react";
import { useSelector } from "react-redux";

const TitleManager = () => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const newTitle = user?.nivel === "admin" ? "YetzScore - ADM" : "YetzScore";

    document.title = newTitle;
    setTimeout(() => {
      document.title = newTitle;
    }, 0);
  }, [user]);

  return null;
};

export default TitleManager;