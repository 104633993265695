import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ReactDOM from 'react-dom';

import { useDispatch, useSelector } from "react-redux";
import { Creators as AcaoCreators } from "../../store/ducks/acaoAdm";
import ModalAction from "../../components/ModalActions";
import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";

import {
  FormInitialState,
  validationCriacaoAcao,
  validationEdicaoAcao
} from "../../utils/configForm";
import Select from "../../components/SelectCustom";
import Button from "../../components/button";
import InputCalendar from "../../components/inputCalendar";

import closeImg from "../../assets/img/sistema/close.svg";
import plusImg from "../../assets/img/sistema/plus.svg";
import editImg from "../../assets/img/sistema/editar.svg";

import { NovaAcao } from "./styles";
import { formataMoney, formatCurrentMoney, tipoFinanciamento } from "../../utils/helper";
import Toggles from "../../components/filtroGerenciaAdm/toggles";
import Modal from "../../components/modal";
import { DebounceInput } from "react-debounce-input";
import { api } from "../../services/api";
import { toast } from "react-toastify";

function FormAcao({ initialValues }) {
  const [isConfirmationEditModalOpen, setisConfirmationEditModalOpen] = useState(false);
  const { data } = useSelector((state) => state.cargaAcaoAdm);
  const dispatch = useDispatch();
  const { modal_action } = useSelector((state) => state.modalAction);
  const [config, setConfig] = useState();
  const { acao_id } = useSelector((state) => state.acaoAdm);
  const [valueFinanciamentoValor, setValueFinanciamentoValor] = useState("");
  const options = [
    // { value: 1, label: "Seguro" },
    { value: 2, label: "Financiamento" },
    // { value: 3, label: "cartao_mit" },
    // { value: 4, label: "speed" },
    { value: 5, label: "financiamento_valor" },
    { value: 6, label: "financiamento_valor_fixo" },
  ];

  
  useEffect(() => {
    if (initialValues?.financiamento_valor_max) {
      setValueFinanciamentoValor(
        `R$${formatCurrentMoney(initialValues?.financiamento_valor_max)}`
      );
    }
  }, [initialValues?.financiamento_valor_max]);

  function closeModal() {
    dispatch(ModalActionCreators.close());
    setisConfirmationEditModalOpen(false);
  }
  
  const handleEditConfirm = (values) => {
    setisConfirmationEditModalOpen(true);
    setConfig({
      title: "Confirmar",
      description: "Confirmar alterações nesta ação?",
      onClick: () => {
        dispatch(AcaoCreators.update(values))
        closeModal();
      },
      closeModal: closeModal
    });
    dispatch(ModalActionCreators.open(config));
  };

  async function updateFinanciamentoValor(valor) {
    try {
      await api.post(`acao/${acao_id}`, {
        financiamento_valor_max: valor,
      });
      toast.success("Valor atualizado com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar valor");
      console.log(error);
    }
  }

  const isEditMode = !!initialValues;

  return (
    <NovaAcao>
      <div className="container-header">
        <p>
          <img src={isEditMode ? editImg : plusImg} alt="" /> {isEditMode ? "Editar Campanha" : "Nova ação"}
        </p>
        <button onClick={() => dispatch(isEditMode ? AcaoCreators.open_modal_update_acao() : AcaoCreators.open_modal_store_acao())}>
          <img src={closeImg} alt="" />
        </button>
      </div>

      <div className="container-acao">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues || FormInitialState.criarAcao}
          validationSchema={isEditMode ? validationEdicaoAcao : validationCriacaoAcao}
          onSubmit={(value, actions) => {
            if (value?.financiamento_valor_fixo) {
              value = {
                ...value,
                financiamento_valor_max: parseFloat(
                  value?.financiamento_valor_fixo
                    ?.replace(/[^0-9!,]/g, "")
                    ?.replace(",", ".")
                ),
              };
            } else {
              value = {
                ...value,
                financiamento_valor_max: parseFloat(
                  value.financiamento_valor_max
                    ?.replace(/[^0-9!,]/g, "")
                    ?.replace(",", ".") || "0"
                ),
              };
            }

            if (value.type === "financiamento") {
              value = {
                ...value,
                seller_amount: parseFloat(
                  value.seller_amount
                    ?.replace(/[^0-9!,]/g, "")
                    ?.replace(",", ".") || "0"
                ),
                manager_amount: parseFloat(
                  value.manager_amount
                    ?.replace(/[^0-9!,]/g, "")
                    ?.replace(",", ".") || "0"
                ),
              };
            }

            delete value?.financiamento_valor_fixo;

            if (isEditMode) {
              dispatch(AcaoCreators.update(value));
            } else {
              dispatch(AcaoCreators.store(value));
            }
          }}
        >
          {(props) => {
            const { setFieldValue, values } = props;

            return (
              <Form>
                {!isEditMode && <Select
                  id={1}
                  options={options}
                  label="Tipo da Ação"
                  change={({ value, label }) => {
                    if (
                      label === "financiamento_valor_fixo" ||
                      label === "financiamento_valor"
                    ) {
                      setFieldValue("financiamento_valor_max", "");
                      setFieldValue("financiamento_valor_fixo", "");
                    }
                    setFieldValue("type", label.toLowerCase());
                  }}
                />}
                <ErrorMessage component="span" name="type" />

                <div className="container-input">
                  <Field type="text" name="nome" placeholder="Nome da Ação" />
                  <ErrorMessage component="span" name="nome" />
                </div>

                <InputCalendar
                  label="Data inicio"
                  onChange={(change) => setFieldValue("start_period", change)}
                  initialValue={initialValues?.start_period}
                />

                <InputCalendar
                  label="Data Termino"
                  onChange={(change) => setFieldValue("end_period", change)}
                  initialValue={initialValues?.end_period}
                />

                {values.type === "financiamento_valor" && !isEditMode && (
                  <div className="container-input">
                    <Field
                      type="text"
                      value={values.financiamento_valor_max}
                      onChange={(change) => {
                        const value = change.target.value;
                        setFieldValue(
                          "financiamento_valor_max",
                          formataMoney(value)
                        );
                      }}
                      name="financiamento_valor_max"
                      placeholder="Valor máximo"
                    />
                    <ErrorMessage
                      component="span"
                      name="financiamento_valor_max"
                    />
                  </div>
                )}

                {values.type === "financiamento_valor_fixo" && (
                  <div className="container-input">
                    <Field
                      type="text"
                      value={values.financiamento_valor_fixo}
                      onChange={(change) => {
                        const value = change.target.value;
                        setFieldValue(
                          "financiamento_valor_fixo",
                          formataMoney(value)
                        );
                      }}
                      name="financiamento_valor_fixo"
                      placeholder="Valor máximo"
                    />
                    <ErrorMessage
                      component="span"
                      name="financiamento_valor_fixo"
                    />
                  </div>
                )}
              
                {values.type === "financiamento" && (
                  <div>
                    <div className="container-input">
                      <Field
                        type="text"
                        value={values.seller_amount}
                        onChange={(change) => {
                          const value = change.target.value;
                          setFieldValue(
                            "seller_amount",
                            formataMoney(value)
                          );
                        }}
                        name="seller_amount"
                        placeholder="Valor vendedor"
                      />
                      <ErrorMessage
                        component="span"
                        name="seller_amount"
                      />
                    </div>
                    <div className="container-input">
                      <Field
                        type="text"
                        value={values.manager_amount}
                        onChange={(change) => {
                          const value = change.target.value;
                          setFieldValue(
                            "manager_amount",
                            formataMoney(value)
                          );
                        }}
                        name="manager_amount"
                        placeholder="Valor gerente"
                      />
                      <ErrorMessage
                        component="span"
                        name="manager_amount"
                      />
                    </div>
                  </div>
                )}

                {isEditMode && (tipoFinanciamento.includes(values.type) || tipoFinanciamento.includes(values.action_type)) ? (
                  <DebounceInput
                    type="text"
                    debounceTimeout={1000}
                    value={valueFinanciamentoValor}
                    placeholder="R$ 0,00"
                    onChange={async (change) => {
                      const value = formataMoney(change.target.value);
                      const formated = parseFloat(
                        value.replace(/[^0-9!,]/g, "").replace(",", ".") || "0"
                      );
                      setValueFinanciamentoValor(value);
                      await updateFinanciamentoValor(formated);
                    }}
                  />
                ) : (
                  <></>
                )}

                {!!isEditMode && <Toggles data={data} />}

                <div style={{display:"flex",justifyContent:"flex-end"}}>
                <Button
                  active={true}
                  title={isEditMode ? "CONFIRMAR" : "ENVIAR"}
                  type={isEditMode ? "button" : "submit"}
                  onClick={isEditMode ? () => handleEditConfirm(values) : null} // Corrigir a chamada da função
                />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {isConfirmationEditModalOpen && ReactDOM.createPortal(
          <Modal component={ModalAction} active={modal_action} rest={config} />,
          document.body
      )}
    </NovaAcao>
  );
}

export default FormAcao;