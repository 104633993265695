import styled from "styled-components";
export const Container = styled.div`
  background: #ffffff;
  border-radius: 8px;
  width: 859px;
  overflow: hidden;
  &.finalizar {
    width: 340px;
    height: 317px;
    padding-top: 56px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    text-align: center;
    &.alerta {
      h3 {
        margin-bottom: 17px;
      }

      p {
        margin-bottom: 33px;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 11px;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;
      text-align: center;
      color: #000000;
      width: 290px;
      margin: 0 auto;
      margin-bottom: 39px;
    }
  }
  &.vinculo {
    width: auto;
    height: auto;
    .content {
      width: 100%;
      padding: 0px 32px 45px 22px;
      &.alerta {
        text-align: center;
        .container-btns {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        line-height: 16.8px;
        color: #5e5f5f;
        margin-bottom: 17px;
        &:last-child {
          margin-bottom: 0px;
        }
        strong {
          font-weight: bold;
        }
      }

      .container-btns {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        button {
          width: 120px;
          margin-top: 10px;
        }
      }
    }
  }
  .content {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 25px;
  }
`;

export const Header = styled.div`
  padding: 32px 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  .container-img-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    img {
      width: 69px;
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 28px;
      color: #000000;
      text-align: center;
    }
  }

  .container-etapas {
    display: flex;
    align-items: center;
    margin-left: auto;
    p {
      width: 114px;
      background-color: #dedede;
      height: 1px;
      margin: 0px 10px;
    }
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      color: #c4c4c4;
      transition: all 0.5s;
      &.active {
        color: #ff9029;
        transition: all 0.5s;
      }
    }
  }

  .container-img img {
    width: 14px;
  }

  .container-img {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 15px;
  }
`;

export const FiltroStyles = styled.div`
  padding-top: 33px;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    color: #4a4a4a;
    margin-bottom: 14px;
  }
  .container-input {
    margin-bottom: 14px;
    input {
      border: 1.5px solid #e0e0e0;
      border-radius: 4px;
      width: 100%;
      height: 54px;

      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      font-size: 16px;
      line-height: 100%;
      color: #666666;
      background-color: transparent;
      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        color: #666666;
      }
    }
  }
`;

export const ListaStyles = styled.div`
  ul {
    width: 100%;
    height: 200px;
    overflow: auto;
    margin-bottom: 20px;
    li {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
      background: #f8f8f8;
      border-radius: 4px;
      align-items: center;
      width: 100%;
      height: 47px;
      margin-bottom: 7px;
      padding-left: 16px;
      padding-right: 16px;
      transition: background 0.5s;
      cursor: pointer;
      &:hover {
        background: #ffe9dd;
        transition: background 0.5s;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 100%;
        color: #4a4a4a;
        margin-left: 40px;
        &:first-child {
          font-weight: bold;
          margin-left: 0px;
        }

        &:last-child {
          margin-left: 70px;
        }
      }
    }
  }
`;

export const ToggleFilterStyles = styled.div`
  .container-toggle {
    position: relative;
    .qtd-voucher {
      margin-left: 10px;
    }
    .container-list {
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s;
      &.active {
        position: static;
        opacity: 1;
        pointer-events: all;
        transition: all 0.5s;
      }
    }

    .container-result {
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s;
      &.active {
        position: static;
        opacity: 1;
        pointer-events: all;
        transition: all 0.5s;
      }

      .header-vinculo {
        display: grid;
        grid-template-columns: 1fr 167px;
        grid-gap: 22px;
        .item-info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 26px;
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 100%;
            color: #4a4a4a;
            strong {
              font-weight: bold;
            }
          }
        }

        .header-contador {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 18px;
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 100%;
            color: #4a4a4a;
          }
          button {
            border: none;
            background-color: transparent;
          }
        }
      }

      .container-vinculo {
        display: grid;
        grid-template-columns: 1fr 167px;
        grid-gap: 22px;
        align-items: center;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 16px;
        }
        .card-vinculo {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 160px 16px;
          grid-gap: 10px;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          border: 1.5px solid #e0e0e0;
          align-items: center;
          width: 100%;
          height: 47px;
          padding-left: 16px;
          padding-right: 16px;
          transition: background 0.5s;
          cursor: pointer;
          button {
            width: auto;
            height: auto;
            background-color: transparent;
            border: none;
          }
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 100%;
            color: #4a4a4a;
            &:first-child {
              font-weight: bold;
            }
          }
        }
      }

      .container-btns {
        button {
          width: fit-content;
          padding: 0 32px;
          &:first-child {
            margin-right: 16px;
          }
        }
      }
    }
  }
`;
